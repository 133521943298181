* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter";
  padding-bottom: 120px;
}

h1 {
  font-size: 30px;
  /* font-size: 2.7vw; */
  /* font-weight: 600; */}

h2 {
  font-size: 26px;
  /* font-size: 2vw; */
  /* font-weight: 600; */
}

h3 {
  font-size: 22px;
  /* font-size: 1.7vw; */
  /* font-weight: 600; */
}

h4 {
  font-size: 18px;
  /* font-size: 1.6vw; */
  /* font-weight: 600; */
}

p {
  font-size: 16px;
  /* font-size: 1.2vw; */
}

thead, tbody {
  font-size: 16px;
  /* font-size: 1.2vw; */
}

ul {
  font-size: 16px;
  /* font-size: 1.2vw; */
}

b {
  font-weight: 600;
}

header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  /* background-color: red; */
  color: white;
  text-align: center;
}

/* above navbar */
.header-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 10px 20px;
  /* height: 10vw; */
  height: 120px;
}

/* TODO: sort out button scaling with window size change */
#contact-btn {
  /* width: 3vw; */
  /* height: 3vw; */
  background-color: #29347C;
  align-items: center;
  position: relative;
  /* font-size: 1.2vw; */
}

#contact-btn:hover {
  background-color: #B4284A;
}

.our-logo img {
  /* width: 25vw; */
  /* min-width: 50px; */
  width: 300px;
  height: auto;
  padding-top: 10px;
  padding-left: 28px;
}

/* For Mobile */
@media screen and (max-width: 540px) {
  .our-logo img {
    /* width: 25vw; */
    /* min-width: 50px; */
    width: 200px;
    height: auto;
    padding-top: 15px;
    padding-left: 2px;
  }
}

.rya-logo img {
  /* width: 10vw; */
  width: 150px;
  /* min-width: 25px; */
  height: auto;
}

@media screen and (max-width: 540px) {
  .rya-logo img {
    /* width: 10vw; */
    width: 100px;
    /* min-width: 25px; */
    height: auto;
  }
}

@media screen and (max-width: 540px) {
  thead, tbody {
    font-size: 10px;
  }
}

/* #contact-logos {
  display: flex;
  align-items: left;
} */

/* TODO: make navbar thinner */
/* .nav-area {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 5px;
  height: 60px;
  background-color: #6FA8DC;
} */

/* .nav-area {
  color: white;
  background-color: #29347C;
} */

/* .navbar-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
} */

.newNav {
  display: flex;
  align-items: center;
  /* max-width: 100%; */
  margin: 0 auto;
  /* padding-top: 5px; */
  /* height: 2.7vw; */
  background-color: #29347C;
}

.newNav a {
  /* color: white; */
  /* font-size: 1.4vw; */
  font-size: 20px;
  /* padding-left: 2vw;
  padding-right: 2vw; */
}

.navbar-light .navbar-brand {
  color: white;
}

.navbar-light .navbar-brand:hover {
  color: white;
}

#navbarScrollingDropdown {
  color: white;
}

#navbarLink {
  color: white;
}

.dropdown-toggle::after {
  color: #B4284A;
}

/* .navbar-light .navbar-nav .nav-link {
  color: white;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.691);
} */

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

/* content */

.content {
  max-width: 95%;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

/* general table style */
thead tr th,
tbody tr td {
  padding: 5px;
  border: 1px solid #7f7f7f;
}

th {
  font-weight: normal;
}

.home-content {
  text-align: left;
}

.home-content img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.home-content a {
  text-align: center;
  color: #B4284A;
  margin-right: 1vw;
}

.home-content h5 {
  font-size: 20px;
}

.home-content a:hover {
  color: #29347C;
}

/* #home-markdown {
  margin-bottom: 50px;
} */

/* #four-square {
  padding-top: 50px;
} */

.power-content {
  padding: 20px;
  background: linear-gradient(rgba(240, 240, 240, 0.8), rgba(240, 240, 240, 0.8)),
    url("../public/img/powerboat.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  border-radius: 5px;
}

.power-content h1 {
  color: #FA8C1D;
}

.power-content img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.power-content .btn-primary {
  border-radius: 5px;
  margin-right: 3px;
  padding: 0.5vw;
  margin-bottom: 0.5vw;
  background-color: #29347C;
  color: #fff;
}

.power-content .btn-primary:hover {
  background-color: #B4284A;
  color: #fff;
}

/* .power-content th {
  font-weight: normal;
} */

.power-content thead tr th:first-child,
.power-content tbody tr td:first-child {
  background-color: #FA8C1D;
}

.motor-content {
  padding: 20px;
  background: linear-gradient(rgba(240, 240, 240, 0.8), rgba(240, 240, 240, 0.8)),
    url("../public/img/motorboat.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  border-radius: 5px;
}

.motor-content h1 {
  color: #21ADEF;
}

.motor-content img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.motor-content thead tr th:first-child,
.motor-content tbody tr td:first-child {
  background-color: #21ADEF;
}

.motor-content .btn-primary {
  border-radius: 5px;
  margin-right: 3px;
  padding: 0.5vw;
  margin-bottom: 0.5vw;
  background-color: #29347C;
  color: #fff;
}

.motor-content .btn-primary:hover {
  background-color: #B4284A;
  color: #fff;
}

.sail-content {
  padding: 20px;
  background: linear-gradient(rgba(240, 240, 240, 0.8), rgba(240, 240, 240, 0.8)),
    url("../public/img/sailboat.jpeg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  border-radius: 5px;
}

.sail-content h1 {
  color: #1372BB;
}

.sail-content img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.sail-content thead tr th:first-child,
.sail-content tbody tr td:first-child {
  background-color: #1372BB;
  color: #fff;
}

.sail-content .btn-primary {
  border-radius: 5px;
  margin-right: 3px;
  padding: 0.5vw;
  margin-bottom: 0.5vw;
  background-color: #29347C;
  color: #fff;
}

.sail-content .btn-primary:hover {
  background-color: #B4284A;
  color: #fff;
}

.shorebased-content {
  padding: 20px;
  background: linear-gradient(rgba(240, 240, 240, 0.8), rgba(240, 240, 240, 0.8)),
    url("../public/img/theory.jpeg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  border-radius: 5px;
}

.shorebased-content h1 {
  color: #A25103;
}

.shorebased-content img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.shorebased-content thead tr th:first-child,
.shorebased-content tbody tr td:first-child {
  background-color: #A25103;
  color: #fff;
}

.shorebased-content .btn-primary {
  border-radius: 5px;
  margin-right: 3px;
  padding: 0.5vw;
  margin-bottom: 0.5vw;
  background-color: #29347C;
  color: #fff;
}

.shorebased-content .btn-primary:hover {
  background-color: #B4284A;
  color: #fff;
}

.own-boat-content {
  padding: 20px;
  border-radius: 5px;
}

.own-boat-content h1 {
  color: #53738A;
}

.own-boat-content img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.own-boat-content .btn-primary {
  border-radius: 5px;
  margin-right: 3px;
  padding: 0.5vw;
  margin-bottom: 0.5vw;
  background-color: #29347C;
  color: #fff;
}

.own-boat-content .btn-primary:hover {
  background-color: #B4284A;
  color: #fff;
}

#sailboat-charter {
  padding: 20px;
  background: linear-gradient(rgba(240, 240, 240, 0.8), rgba(240, 240, 240, 0.8)),
    url("../public/img/yacht_charter.jpeg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  border-radius: 5px;
}

#powerboat-charter {
  padding: 20px;
  background: linear-gradient(rgba(240, 240, 240, 0.8), rgba(240, 240, 240, 0.8)),
    url("../public/img/rib_charter.jpeg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  border-radius: 5px;
}

.boat-charter-content h1 {
  color: #B4284A;
}

.boat-charter-content img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.boat-charter-content thead tr th:first-child,
.boat-charter-content tbody tr td:first-child {
  background-color: #B4284A;
  color: #fff;
}

.boat-charter-content .btn-primary {
  border-radius: 5px;
  margin-right: 3px;
  padding: 0.5vw;
  margin-bottom: 0.5vw;
  background-color: #29347C;
  color: #fff;
}

.boat-charter-content .btn-primary:hover {
  background-color: #B4284A;
  color: #fff;
}

.about-content h1 {
  color: #514d45;
}

.about-content img {
  width: 70%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 50%;
  margin: 15%;
  clip-path: circle();
}

/* Relevant styles */
.about-content .image-stack {
  display: grid;
  /* imperative for the overlapping to work */
  position: relative;
  grid-template-columns: repeat(12, 1fr);
}

.about-content .image-stack__item--bottom {
  grid-column: 4 / -1;
  grid-row: 1;
  padding-top: 10%;
}

.about-content .image-stack__item--top {
  grid-row: 1;
  grid-column: 1 / span 8;
  filter: brightness(1.75);
  /* slightly arbitrary, keeps proportion once resized */
  /* padding-top: 10%; */
  /* tells the browser to make this image on top */
  z-index: 1;
}

.tcs-content {
  padding: 20px;
  border-radius: 5px;
}

.tcs-content h2 {
  padding-top: 0.5vw;
  /* text-align: center; */
  /* align-items: center; */
  /* color: red; */
}

.tcs-content h4 {
  padding-top: 0.3vw;
}

.privacy-content {
  padding: 20px;
  border-radius: 5px;
}

.privacy-content h2 {
  padding-top: 0.5vw;
  /* text-align: center; */
  /* align-items: center; */
  /* color: red; */
}

.privacy-content h4 {
  padding-top: 0.3vw;
}

.vouchers-content {
  text-align: center;
  width: 100%;
  padding: 100px;
  background: linear-gradient(rgba(240, 240, 240, 0.9), rgba(240, 240, 240, 0.9)),
    url("../public/img/motor-power-sail-theory-combo.png");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 5px;
}

.contact-content {
  padding: 20px;
  border-radius: 5px;
}

.contact-content li {
  list-style: none;
  margin-left: -2vw;
  margin-bottom: 0.2vw;
}

.booking-content {
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  display: flex;
}

.booking-content input,
.booking-content select {
  width: 100%;
  /* height: 3vw; */
  border-radius: 5px;
  border: 1px solid #b8b8b8;
  padding: 0.5vw;
  margin-bottom: 0.5vw;
}

.booking-content textarea {
  width: 100%;
  /* height: 5vw; */
  border-radius: 5px;
  border: 1px solid #b8b8b8;
  padding: 0.5vw;
  margin-bottom: 0.5vw;
}

.booking-content button {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #b8b8b8;
  padding: 0.5vw;
  margin-bottom: 0.5vw;
  background-color: #29347C;
  color: #fff;
}

.booking-content button:hover {
  background-color: #B4284A;
  color: #fff;
}

.booking-content h2 {
  padding-top: 20px;
}

.booking-content .css-b62m3t-container {
  padding-top: 5px;
  padding-bottom: 5px;
}

.booking-content button.dropdown-multi-select {
  width: 100%;
  /* height: 3vw; */
  border-radius: 5px;
  border: 1px solid #b8b8b8;
  padding: 0.5vw;
  margin-bottom: 0.5vw;
}

.booking-content .form-check-input {
  width: 10px;
  height: 10px;
}

.booking-content .form-check-input[type="checkbox"] {
  border-radius: 2px;
  margin-top: 0;
  margin-right: 0;
}

.booking-content input#health {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

/* .booking-content #billing-details {
  overflow-anchor: none;
} */

.map {
  width: 90%;
  height: 100%;
  border: 1px solid #b8b8b8;
}

#credits-footer {
  padding: 20px;
  /* padding-right: 2000px; */
  background-color: #29347C;
  color: #fff;
  /* height: 50px; */
}
